import { useAuth0 } from '@auth0/auth0-react'
import { isBrowser } from '@klickmarketing/react-components'
import { navigate } from '@reach/router'

import { getParamFromUrl } from '../common/utils'

export default function PostReset() {
  const { loginWithRedirect } = useAuth0()
  const success = getParamFromUrl('success')
  const email = getParamFromUrl('email')

  if (success) {
    const returnTo = isBrowser && window.location.pathname

    loginWithRedirect({
      appState: {
        email,
        returnTo,
      },
      login_hint: email,
    })
  }

  if (!success) {
    navigate('/')
  }

  return null
}
